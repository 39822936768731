@use 'mixins';
@use 'variables';

.info-section {
	&__text {
		margin-top: var(--spacing-md);
	}

	&__heading {
		@include mixins.font-t2;
	}

	@media not screen and (min-width: variables.$breakpoint-md) {
		&__heading {
			text-align: right;
		}

		&--is-alternate &__heading {
			text-align: left;
		}

		&__text {
			padding: 0 var(--spacing-sm);
		}

		&__image {
			margin-top: var(--spacing-md);
		}
	}

	@media (min-width: variables.$breakpoint-md) {
		display: flex;
		flex-direction: row-reverse;
		align-items: flex-start;

		&--is-alternate {
			flex-direction: row;
		}

		&__info {
			padding: 0 var(--spacing-lg);
		}

		&__image {
			flex: 1 0 52vw;
		}
	}
}
