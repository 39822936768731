@use 'mixins';
@use 'variables';

.space-preview {
	@include mixins.stack($size: sm);

	position: relative;

	&__heading {
		--badge-border-color: transparent;
		--badge-background-color: var(--color-core-black);
		--badge-text-color: var(
			--body-background-color-hex,
			var(--color-core-sand)
		);
	}

	&__body {
		padding: 0 var(--space-preview-body-padding-inline, 0);
	}

	&__text {
		margin-top: var(--spacing-sm);
	}

	&__link {
		display: inline-block;
		margin-top: var(--spacing-md);
	}

	@media (min-width: variables.$breakpoint-md) {
		&--is-responsive {
			@include mixins.unstack;

			display: flex;
			flex-direction: var(--space-preview-flex-direction, row);
			align-items: flex-start;
			padding: 0;
		}

		&--is-responsive &__image {
			flex: 1 0 52%;
		}

		&--is-responsive &__body {
			padding: 0 var(--spacing-lg);
		}
	}
}
