@use 'mixins';
@use 'variables';

.item {
	position: relative;

	border-top: 1px solid;
}

.item:last-child {
	border-bottom: 1px solid;
}

.header {
	position: relative;
	padding: var(--spacing-sm) 0;
}

.image-wrapper {
	display: none;
}

.button {
	@include mixins.breakout($position: 'before');
	@include mixins.font-t2;

	text-align: left;
	// HACK: prevent letters like `C` and `O` from being slightly cut off on the left side on Safari
	text-indent: var(--text-indent, 0.0625rem);
}

.subheading {
	@include mixins.font-t5;
}

.panel {
	@include mixins.stack($size: sm);

	padding-bottom: var(--spacing-sm);
}

.panel[aria-hidden='true'] {
	display: none;
}

@media (min-width: variables.$breakpoint-md) {
	.item {
		display: grid;
		position: relative;
		grid-template-columns: auto var(--image-column-width, 10vw);
		grid-template-rows: auto 1fr;
		column-gap: var(--spacing-md);
	}

	.item.is-expanded {
		--image-column-width: 30vw;
	}

	.header {
		position: static;
		grid-column: 1;
		grid-row: 1;
	}

	.button {
		@include mixins.breakout($position: 'after');

		--text-indent: 0.1875rem;
	}

	.button::before {
		grid-column: 1 / 3;
		grid-row: 1 / 2;
	}

	.button::after {
		grid-column: 2 / 3;
		grid-row: 2 / 3;
	}

	.image-wrapper {
		display: block;
		position: relative;
		grid-column: 2 / 3;
		grid-row: 1 / 3;
		margin: var(--spacing-xs) 0;
	}

	.image {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		width: 100%;
		height: 100%;
		object-fit: cover;
		object-position: center center;
		mix-blend-mode: var(--mix-blend-mode, multiply);
		filter: var(--grayscale-filter, grayscale(1));

		aspect-ratio: 1;
	}

	.is-expanded .image {
		--mix-blend-mode: normal;
		--grayscale-filter: grayscale(0);

		position: static;
	}

	.panel {
		@include mixins.unstack;

		grid-column: 1;
		grid-row: 2;

		padding-bottom: var(--spacing-md);
	}

	.panel-image {
		display: none;
	}

	.text {
		@include mixins.stack($size: md);

		max-width: 60ch;
	}
}
