@use 'variables';
@use 'mixins';

.video-section {
	&__heading {
		@include mixins.font-t2;

		--marquee-duration-per-character: 600ms;

		margin-top: var(--spacing-xs);
	}

	&__text {
		box-sizing: content-box;
		max-width: 40ch;
		margin-top: var(--spacing-sm);
		padding: 0 var(--spacing-sm);
	}

	@media (min-width: variables.$breakpoint-md) {
		&__text {
			padding: 0 var(--spacing-lg);
		}
	}
}
