.illustration {
	position: relative;
	max-width: var(--illustration-max-width, auto);
	max-height: var(--illustration-max-height, auto);
	aspect-ratio: var(--illustration-aspect-ratio);
}

.image {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
}

@supports not (aspect-ratio: 1) {
	.illustration::after {
		content: '';

		display: block;
		padding-top: calc(100% / var(--illustration-aspect-ratio));
	}

	.image {
		width: 100%;
		height: 100%;
		object-fit: contain;
	}
}
