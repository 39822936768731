@use 'mixins';
@use 'variables';

.space-list {
	&__heading {
		@include mixins.font-t2;
	}

	&__previews {
		display: flex;
		flex-direction: column;
		gap: var(--spacing-md);
	}

	&--is-compact &__previews {
		padding: 0 var(--spacing-sm);
	}

	@media (min-width: variables.$breakpoint-md) {
		&__previews {
			gap: var(--spacing-xl);
		}

		&--is-compact &__previews {
			flex-direction: row;
			gap: var(--spacing-md);

			padding: 0 var(--spacing-lg);
		}

		&__preview:nth-child(odd) {
			--space-preview-flex-direction: row-reverse;
		}
	}
}
