@use 'mixins';
@use 'variables';

.form {
	@include mixins.stack($size: sm);
}

.title {
	@include mixins.font-t2;

	max-width: 50vw;
}

.text,
.form {
	padding: 0 var(--spacing-sm);
}

.fields {
	@include mixins.stack($size: sm);

	--button-background-color: var(--color-core-white);
	--button-border-color: transparent;
}

.error {
	color: var(--color-error);
}

.guest-count {
	display: none;
}

@media (min-width: variables.$breakpoint-md) {
	.form {
		@include mixins.unstack;

		display: flex;
		justify-content: space-between;
	}

	.header {
		flex: 0 0 40%;
	}

	.text {
		padding: 0 0 0 var(--spacing-lg);
	}

	.fields {
		flex: 0 0 50%;
		padding: 0 var(--spacing-lg) 0 0;
	}
}
