@use 'variables';
@use 'mixins';

.is-hidden {
	display: none;
}

.cover {
	--content-color: var(--color-core-black);
	--background-color: var(--color-core-gravel);

	&.is-light-text {
		--content-color: var(--color-core-gravel);
		--background-color: var(--color-core-black);

		--button-text-color: var(--content-color);
		--button-border-color: var(--content-color);
	}

	&.is-fill.is-light-text {
		--content-color: var(--color-core-white);
	}

	display: flex;
	flex-direction: column;
	min-height: var(--svh-100);
	margin-top: calc(2 * var(--navigation-height) * -1);
	padding-top: calc(2 * var(--navigation-height));

	background-color: var(--background-color);

	> :first-child {
		display: flex;
		flex-grow: 1;
	}

	@media (min-width: variables.$breakpoint-md) {
		margin-top: calc(var(--navigation-height) * -1);
		padding-top: var(--navigation-height);

		&.is-fill {
			padding-top: 0;
		}
	}
}

.main {
	display: flex;
	position: relative;
	flex-direction: column;
	width: 100%;
	padding: var(--spacing-sm) var(--spacing-sm) 2.875rem;
	@include mixins.stack($size: sm);

	background-color: var(--background-color);

	color: var(--content-color);

	&.is-fill {
		flex-direction: row;
		align-items: flex-end;
		padding: 0;

		&::after {
			content: '';

			display: block;
			position: absolute;
			z-index: 1;
			top: 0;
			bottom: 0;
			width: 100%;
			height: 100%;

			background-color: var(--color-core-white-05);
		}

		&.is-light-text::after {
			background-color: var(--color-core-black-05);
		}
	}

	@media (min-width: variables.$breakpoint-md) {
		@include mixins.unstack();

		&:not(&.is-fill) {
			display: grid;
			grid-auto-columns: 1fr;
			grid-auto-flow: column;
			padding: 0;

			> :last-child {
				display: flex;
				flex-direction: column;
				justify-content: space-between;
			}

			> :first-child {
				order: 1;
			}
		}
	}
}

.content {
	display: flex;
	flex-direction: column;
	justify-content: space-between;

	@include mixins.stack($size: md);

	&.is-text-only {
		flex-grow: 1;
	}

	> :first-child {
		@include mixins.stack($size: xs);
	}

	&.is-fill {
		position: relative;
		z-index: 2;
		padding: var(--spacing-sm) var(--spacing-sm) 2.875rem;
		overflow-x: hidden;
	}

	&.is-fill,
	&.is-text-only {
		@media (min-width: variables.$breakpoint-md) {
			@include mixins.stack($size: md);

			margin-inline: auto;

			text-align: center;

			.cta-button {
				display: inline-block;
				padding-inline: var(--spacing-md);
			}
		}
	}

	@media (min-width: variables.$breakpoint-md) {
		padding: var(--spacing-sm);
		padding-right: var(--spacing-md);

		background-color: transparent;

		&.has-ticker {
			padding-bottom: 0;
		}
	}
}

.title-section {
	&.is-text-only {
		display: flex;
		flex-grow: 1;
		align-items: center;
	}
}

.media {
	flex-basis: 0;
	flex-grow: 1;

	img,
	video {
		width: 100%;
		height: 100%;
		object-fit: cover;
	}

	&.is-fill {
		position: absolute;
		top: 0;
		bottom: 0;
		width: 100%;
		height: 100%;
	}

	@media (min-width: variables.$breakpoint-md) {
		position: relative;
		width: 100%;
		height: 100%;

		> * {
			position: absolute;
		}
	}
}

.ticker-divider {
	font-family: var(--font-stack-title);
}

.marquee {
	overflow: visible !important;

	color: var(--content-color);
	text-transform: uppercase;
	@include mixins.font-t6;

	@media (min-width: variables.$breakpoint-md) {
		--marquee-padding: var(--spacing-sm);
	}
}

.addition-info {
	@include mixins.stack($size: md);
}

.newsletter {
	max-width: 42rem;
	margin: auto;
}
