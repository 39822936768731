@use 'mixins';
@use 'variables';

.showcase {
	@include mixins.stack($size: md);
}

.header {
	position: relative;
}

.heading {
	@include mixins.font-t1;
}

.items {
	padding: 0 var(--spacing-sm);
}

.hand-drawn-star {
	--width: 2.5em;

	display: block;
	position: absolute;
	z-index: -1;
	top: 0;
	left: 0;
	width: var(--width);
	height: calc(var(--width) / (var(--hand-drawn-star-aspect-ratio)));

	transform: translate(0.25em, -0.25em);

	fill: var(--color-core-white);

	font-size: variables.$font-size-t1;
}

@media (min-width: variables.$breakpoint-md) {
	.items {
		padding: 0 var(--spacing-lg);
	}

	.hand-drawn-star {
		transform: translate(1.25em, -0.35em);
	}
}
