@use 'mixins';
@use 'variables';

.job-position {
	position: relative;
	padding: var(--spacing-xs) 0;

	&__header {
		@include mixins.font-t2;

		display: flex;
		justify-content: space-between;
		gap: 0.25em;
	}

	&__arrow {
		--height: 0.76em;

		width: calc(var(--height) * (var(--arrow-serial-output-aspect-ratio)));
		height: var(--height);
	}

	&__description {
		@include mixins.font-t5;
	}

	@media (min-width: variables.$breakpoint-md) {
		padding: var(--spacing-sm) 0;
	}
}
