@use 'mixins';
@use 'variables';

.carousel-section {
	&__heading {
		@include mixins.font-t2;
	}

	&__primary-text,
	&__secondary-text {
		box-sizing: content-box;
		max-width: 42ch;
		margin-top: var(--spacing-md);
		padding: 0 var(--spacing-sm);
	}

	&__carousel {
		margin-top: var(--spacing-lg);
	}

	&__figure {
		position: relative;
	}

	&__image {
		width: calc(90vw * var(--image-scale-factor, 1));
	}

	&__figcaption {
		@include mixins.font-t6($weight: 500);

		position: absolute;
		bottom: 0;
		left: 0;
		width: max-content;
		padding: var(--spacing-2xs) var(--spacing-xs);

		background-color: var(--body-background-color-hex);
	}

	@media (min-width: variables.$breakpoint-md) {
		&__primary-text,
		&__secondary-text {
			margin-top: var(--spacing-sm);
			margin-left: auto;
			padding: 0 var(--spacing-lg);
		}

		&__image {
			max-width: calc(48vw * var(--image-scale-factor, 1));
		}
	}

	@media (min-width: variables.$breakpoint-lg) {
		&__primary-text,
		&__secondary-text {
			margin-left: calc(50vw - var(--spacing-lg));
		}
	}
}
