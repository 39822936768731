@use 'mixins';
@use 'variables';

.teaser-wrapper {
	margin: 0 calc(var(--spacing-sm) * -1);
	--wave-background-svg: url('/svgs/wave-amethyst.svg');
	padding: var(--spacing-sm) 0;
}

.teaser-wrapper--is-in-grid {
	grid-column: 1 / -1;
}

.teaser {
	background-color: var(--color-accent-amethyst);

	&__link {
		@include mixins.font-t5;
		text-transform: initial;

		--button-border-color: transparent;
		--button-background-color: var(--color-core-black);
		--button-text-color: var(--color-core-white);
	}
}

.teaser-lg {
	display: none;
}

.teaser-mobile {
	display: flex;
	flex-direction: column;
	padding: var(--spacing-md) 1.5rem;

	&__heading {
		display: flex;
		margin-bottom: 1.5rem;
	}

	&__heading-text {
		display: flex;
		flex: 1;
		flex-direction: column;
		justify-content: flex-end;
		margin-right: var(--spacing-md);
	}

	&__illustration {
		width: 7.25rem;
	}

	&__text {
		margin-bottom: 1.5rem;
	}
}

@media (min-width: variables.$breakpoint-md) {
	.teaser-wrapper {
		margin: 0 calc(var(--spacing-lg) * -1);
		padding: var(--spacing-md) 0;
	}

	.teaser-mobile {
		display: none;
	}

	.teaser-lg {
		display: grid;
		grid-template-columns: repeat(20, 1fr);
		padding: 3rem var(--spacing-lg);
		column-gap: 1vw;

		&__illustration {
			grid-column: 1 / span 3;
		}

		&__info,
		&__link-wrapper {
			display: flex;
			flex-direction: column;
			justify-content: center;
		}

		&__info {
			grid-column: 5 / span 10;
		}

		&__heading {
			margin-bottom: var(--spacing-sm);
		}

		&__link-wrapper {
			grid-column: 16 / span 5;
		}
	}

	.teaser-wrapper--is-in-grid {
		grid-column: 3;
		margin: 0;
		padding: 0;

		&::before,
		&::after {
			display: none;
		}
	}

	.teaser-wrapper--is-in-grid .teaser-lg {
		display: flex;
		grid-column: 3;
		flex-direction: column;
		padding: 2rem;
		padding: var(--spacing-md) 1.5rem;

		&__heading {
			display: flex;
			margin-bottom: 1.5rem;
		}

		&__illustration {
			width: 12rem;
			margin-bottom: 2rem;
		}

		&__text {
			margin-bottom: 4rem;
		}
	}
}
