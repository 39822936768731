.accordion {
	position: relative;

	&__item {
		display: block;
		box-sizing: content-box;

		border-width: 1px 0 0 0;
		border-style: solid;
		border-color: currentColor;

		&:last-child {
			border-width: 1px 0;
		}
	}
}
