@use 'mixins';
@use 'variables';

.heading {
	@include mixins.font-t2;
}

.awards {
	margin-top: var(--spacing-lg);
}

@media (min-width: variables.$breakpoint-md) {
	.heading {
		padding-left: var(--spacing-lg);
	}

	.awards {
		margin-top: 6rem;
	}
}
