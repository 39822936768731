@use 'mixins';
@use 'variables';

.name {
	@include mixins.font-t4($weight: 700);
}

.quote,
.name {
	margin-top: var(--spacing-sm);
}
