@use 'variables';

.image-section {
	display: flex;
	flex-direction: column;
	max-width: 90rem;
	margin: 0 auto;
	padding: 0 var(--spacing-lg);
	gap: var(--spacing-sm);

	&__image {
		object-fit: cover;

		height: calc((100vw - 2 * var(--spacing-lg)) / (3 / 4));
		&-container {
			display: flex;
			flex: 1;
			&:first-child:last-child img {
				height: calc((100vw - 2 * var(--spacing-lg)) / (16 / 9));
			}
		}
		&--focal-point {
			object-position: var(--focal-point-x) var(--focal-point-y);
		}
	}

	&--single-image {
		padding: 0 var(--spacing-sm);
	}

	@media (min-width: variables.$breakpoint-md) {
		flex-direction: row;
		height: calc(100vw / (16 / 9));
		gap: var(--spacing-lg);
		&__image {
			height: auto;
		}
		&--single-image {
			padding: 0 var(--spacing-lg);
		}
	}
}
