@use 'variables';

.list {
	display: grid;
	grid-template-columns: repeat(var(--columns, 2), 1fr);
	gap: var(--spacing-md);

	padding: 0 var(--spacing-sm);
}

.list > * {
	flex: 1;
}

.logo {
	width: calc(2.25em * var(--award-logo-aspect-ratio));
}

.heading {
	margin-top: var(--spacing-sm);

	font-weight: 700;
}

@media (min-width: variables.$breakpoint-md) {
	.list {
		--columns: 3;

		padding: 0 var(--spacing-lg);
	}
}

@media (min-width: variables.$breakpoint-xl) {
	.list {
		--columns: 5;
	}
}
