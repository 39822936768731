@use 'mixins';
@use 'variables';

.listing-compact {
	display: flex;
	position: relative;
	flex-wrap: wrap;
	align-items: first baseline;

	padding: var(--spacing-sm) 0;

	&--has-border {
		border-top: 1px solid;
	}

	&__date {
		width: 100%;
	}

	&__body {
		flex-basis: calc(100% - 8ch);
	}

	&__anchor > span {
		display: block;
	}

	&__badge-list {
		margin-top: calc(var(--spacing-xs) + var(--spacing-3xs));
	}

	&__time {
		flex-grow: 1;

		text-align: right;
	}

	&__anchors {
		width: 100%;
		min-width: 6em;
		margin-top: var(--spacing-sm);
	}

	@media (hover: hover) {
		&__anchor > span {
			transition: transform 150ms ease-in-out;
		}

		&__anchor:hover > span {
			transform: translateX(0.625rem);
		}
	}

	@media (min-width: variables.$breakpoint-md) {
		display: grid;
		grid-template-columns: repeat(20, 1fr);
		column-gap: 1vw;

		&__date {
			grid-column: 1 / span 4;
		}

		&__body {
			grid-column: 5 / span 13;
		}

		&__badge-list {
			grid-column: 5 / span 16;
		}

		&__time {
			grid-column: 18 / span 3;
		}

		&__anchors {
			display: block;
			grid-column: 18 / span 3;
			width: initial;
			margin-top: initial;
		}

		&--has-ticket-anchors {
			row-gap: 0;

			.listing-compact__body {
				grid-column: 5 / span 9;
			}
			.listing-compact__time {
				grid-column: 14 / span 3;
			}
		}
	}
}
