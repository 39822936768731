@use 'variables';

.page-detail {
	--space-preview-body-padding-inline: var(--spacing-sm);

	&.is-home-page {
		--page-header-padding-block: var(--spacing-lg);

		@media (min-width: variables.$breakpoint-md) {
			--page-header-padding-block: var(--spacing-xl);
		}
	}

	overflow-x: clip;

	&
		> *:not(h1)
		+ *:not(
			[class*='playlist-section'],
			[class*='home-page-teaser-section'],
			[class*='newsletter-section'],
			[class*='just-announced-event-section'],
			[class*='membership-upsell-section']
		) {
		margin-top: var(--spacing-lg);
	}

	@media (min-width: variables.$breakpoint-md) {
		&
			> *:not(h1)
			+ *:not(
				[class*='playlist-section'],
				[class*='home-page-teaser-section'],
				[class*='newsletter-section'],
				[class*='just-announced-event-section'],
				[class*='membership-upsell-section']
			) {
			margin-top: var(--spacing-xl);
		}
	}
}
