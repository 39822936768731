@use 'mixins';
@use 'variables';

.section {
	position: relative;
}

.header {
	position: relative;
}

.title {
	@include mixins.font-t1;

	position: relative;
	z-index: 1;
}

.arrow {
	--width: 4em;
	--translate-y: calc(0.3em + 20%);

	position: absolute;
	bottom: 0;

	left: 0;
	width: var(--width);
	height: calc(var(--width) / (var(--hand-drawn-arrow-2-aspect-ratio)));

	transform: translate(-20%, var(--translate-y));

	fill: var(--color-core-white);

	// using the font-size here to size the arrow
	// we can’t use the mixin here as that contains `overflow: hidden`
	font-size: variables.$font-size-t1;

	pointer-events: none;
}

.list {
	display: flex;
	flex-direction: column;
	gap: var(--spacing-md);

	margin-top: var(--spacing-sm);
	padding: 0 var(--spacing-sm);
}

@media (min-width: variables.$breakpoint-md) {
	.arrow {
		transform: translate(-40%, var(--translate-y));
	}

	.list {
		flex-direction: row;
		padding: 0 var(--spacing-lg);

		> * {
			flex: 1;
		}
	}
}
