@use 'variables';

@keyframes marquee {
	from {
		transform: translateX(0);
	}

	to {
		transform: translateX(-100%);
	}
}

.marquee {
	padding: 0 var(--spacing-sm);

	.logos {
		--marquee-column-gap: 2em;

		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		gap: 1em var(--marquee-column-gap);

		&:not(:first-child) {
			display: none;
		}
	}

	.logo {
		width: calc(min(3em, 6.25rem) * var(--marquee-logo-aspect-ratio));
	}

	@media (min-width: variables.$breakpoint-md) {
		padding: 0 var(--spacing-lg);

		.logos {
			--marquee-column-gap: 4em;
		}
	}

	@media (prefers-reduced-motion: no-preference) {
		&.is-animated {
			--marquee-animation-duration: calc(
				var(--marquee-total-logo-width, 0) *
					var(--marquee-duration-per-pixel, 20ms)
			);
			--marquee-animation: marquee var(--marquee-animation-duration) linear
				infinite;

			display: flex;
			padding: 0;
			overflow: hidden;
		}

		&.is-animated .logos {
			flex-wrap: nowrap;
			justify-content: flex-start;
			padding-left: var(--marquee-column-gap);

			animation: var(--marquee-animation);

			&:not(:first-child) {
				display: flex;
			}
		}
	}
}
