@use 'mixins';
@use 'variables';

.just-announced-event-section {
	position: relative;
	padding: var(--spacing-md) 0;

	background-color: var(--color-accent-seafoam);

	&__heading,
	&__content {
		position: relative;
		z-index: 1;
	}

	&__heading {
		@include mixins.font-t1;
	}

	&__content {
		margin-top: var(--spacing-md);
		padding: 0 var(--spacing-sm);
	}

	&__events {
		margin-top: var(--spacing-md);
	}

	&__call-to-action-link {
		margin: var(--spacing-md) var(--spacing-sm) 0 auto;
	}

	&__hand-drawn-arrow {
		--width: 45vw;

		position: absolute;
		top: 0;
		left: 0;
		width: var(--width);
		height: calc(var(--width) / (var(--hand-drawn-arrow-1-aspect-ratio)));

		transform: translate(-15%, -5%) rotate(-20deg);

		fill: var(--color-core-white);
	}

	@media (min-width: variables.$breakpoint-md) {
		padding: var(--spacing-lg) 0 var(--spacing-xl);

		&__content {
			--gap: 8vw;
			--available-width: calc(100% - var(--gap));

			display: flow-root;
			padding: 0 var(--spacing-lg);
		}

		&__events:nth-child(1) {
			width: calc(var(--available-width) * 0.38);
			float: left;
		}

		&__events:nth-child(2) {
			width: calc(var(--available-width) * 0.62);
			float: right;
		}

		&__call-to-action-link {
			margin: var(--spacing-md) 0 0 0;
			float: left;
			clear: left;
		}
	}

	@media (min-width: variables.$breakpoint-lg) {
		&__content {
			--gap: 18vw;
		}
	}
}
