@use 'variables';

.split-heading {
	& > * {
		display: block;
		box-sizing: content-box;
	}

	&:not(.center-on-mobile) {
		& > :nth-child(odd) {
			padding-right: var(--spacing-md);

			text-align: left;
		}

		& > :nth-child(even) {
			margin-left: auto;
			padding-left: var(--spacing-md);

			text-align: right;
		}
	}

	&.center-on-mobile {
		& > * {
			padding: 0;

			text-align: center;
		}
	}

	@media (min-width: variables.$breakpoint-md) {
		&.center-on-mobile {
			& > :nth-child(odd) {
				text-align: left;
			}

			& > :nth-child(even) {
				margin-left: auto;

				text-align: right;
			}
		}

		& > :nth-child(odd) {
			padding-right: var(--spacing-lg);
		}

		& > :nth-child(even) {
			padding-left: var(--spacing-lg);
		}
	}
}
