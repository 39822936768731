@use 'mixins';
@use 'variables';

.mission-statement {
	@include mixins.stack($size: lg);

	padding: 0 var(--spacing-sm);

	&__section {
		max-width: 40ch;
	}

	&__header {
		position: relative;
	}

	&__heading,
	&__text {
		position: relative;
		z-index: 1;
	}

	&__heading {
		@include mixins.font-t2;
	}

	&__svg {
		display: block;
		position: absolute;
		bottom: 0;
		left: 0;

		fill: var(--color-core-white);

		font-size: variables.$font-size-t2;
	}

	&__svg--hand-drawn-stroke-1 {
		--width: 5.25em;
		width: var(--width);
		height: calc(var(--width) / (var(--hand-drawn-stroke-1-aspect-ratio)));

		transform: translate(-0.3em, 0.1em);
	}

	&__svg--hand-drawn-star {
		--width: 3.5rem;

		width: var(--width);
		height: calc(var(--width) / (var(--hand-drawn-star-aspect-ratio)));

		transform: translate(2.5em, 0.4em);
	}

	&__svg--hand-drawn-arrow-1 {
		--width: 5.7em;

		right: 0;
		left: auto;
		width: var(--width);
		height: calc(var(--width) / (var(--hand-drawn-arrow-1-aspect-ratio)));

		transform: scaleX(-1) translate(-2.5em, 1.75em) rotate(5deg);
	}

	&__text {
		margin-top: var(--spacing-sm);
	}

	@media (min-width: variables.$breakpoint-md) {
		@include mixins.stack($size: xl);

		padding: 0 var(--spacing-lg);

		&__section {
			width: clamp(30em, 50%, 44ch);

			&:nth-child(odd) {
				margin-left: auto;
			}
		}

		&__svg--hand-drawn-star {
			--width: 4.5em;

			transform: translateX(1.7em);
		}

		&__svg--hand-drawn-arrow-1 {
			--width: 7.25em;

			right: auto;
			left: 0;

			transform: translate(-2.25em, 0.6em);
		}

		&__text {
			margin-top: var(--spacing-md);
		}
	}
}
