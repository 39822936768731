@use 'mixins';

.call-to-action-link {
	@include mixins.font-t3-title;

	display: block;
	width: var(--call-to-action-link-width, auto);
	overflow: hidden;

	&__wrapper {
		position: relative;
		padding: 15% 15% 7%;
	}

	&__wrapper::before {
		content: '';

		display: block;
		position: absolute;
		z-index: 1;

		transform: rotate(14deg);

		background-color: var(--color-core-white);
		inset: 0;
		clip-path: ellipse(50% 45% at 50% 50%);
	}

	&__label {
		position: relative;
		z-index: 2;
	}

	&__arrow {
		--width: calc(var(--call-to-action-link-width) / 2);

		display: block;
		position: relative;
		z-index: 2;
		width: var(--width);
		height: calc(var(--width) / (var(--call-to-action-arrow-aspect-ratio)));
		margin-top: 0.25em;
		margin-right: 10%;
		margin-left: auto;

		fill: currentColor;
	}
}
