@use 'mixins';
@use 'variables';

.newsletter-inline {
	color: inherit;

	.error {
		margin-top: var(--spacing-sm);

		text-align: left;

		a {
			display: flex;
			padding: initial;
		}
	}
}
