@use 'mixins';
@use 'variables';

.event-collection {
	position: relative;
	padding: var(--event-collection-padding-block) 0;

	&__header {
		position: relative;

		margin-bottom: var(--spacing-md);
	}

	&__heading {
		@include mixins.font-t1;

		display: inline-block;

		width: min-content;
		margin: 0 var(--spacing-sm);
	}

	&__description {
		@include mixins.font-t5($weight: 700);

		margin-top: var(--spacing-md);
		margin-left: var(--spacing-sm);
	}

	&__programme {
		--programme-padding-block: 0;
	}

	&__link {
		width: max-content;
		margin: var(--spacing-md) auto 0;
	}

	@media (min-width: 24em) {
		&__heading {
			width: auto;
		}
	}

	@media (min-width: variables.$breakpoint-md) {
		&__link {
			margin-top: var(--spacing-lg);
		}

		&__heading {
			margin: 0 var(--spacing-lg);
		}

		&__description {
			margin-left: var(--spacing-lg);
		}
	}
}
