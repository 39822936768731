@use 'mixins';
@use 'variables';

.playlist-section {
	padding: var(--spacing-2xs) 0 var(--spacing-2xl);

	background-color: var(--background-color, transparent);

	&__heading {
		@include mixins.font-t2;

		--marquee-duration-per-character: 600ms;
	}

	&__content {
		margin-top: var(--spacing-md);
		padding: 0 var(--spacing-sm);
	}

	&__image-wrapper {
		display: none;
	}

	&__text-wrapper:last-child {
		display: none;
	}

	&__playlist-wrapper {
		margin-top: var(--spacing-md);
	}

	&__playlist {
		--spotify-playlist-embed-aspect-ratio: 1.125;
	}

	@media (min-width: variables.$breakpoint-sm) {
		&__playlist {
			--spotify-playlist-embed-aspect-ratio: 0.8;
		}
	}

	@media (min-width: variables.$breakpoint-md) {
		--playlist-width: max(20rem, 22vw);

		display: flow-root;

		&__content {
			display: flow-root;
			margin-top: var(--spacing-lg);
			padding: 0 var(--spacing-lg);
		}

		&__image-wrapper,
		&__playlist-wrapper,
		&__text-wrapper {
			width: 50%;
		}

		&__image-wrapper {
			display: block;
			float: left;
		}

		&__playlist-wrapper {
			margin: 0;
			float: right;
		}

		&__image {
			display: block;
			width: 72%;
			margin: 0 auto;
		}

		&__text-wrapper {
			margin-top: var(--spacing-md);
			padding-left: calc((50% - var(--playlist-width)) / 2);
			float: right;
			clear: right;
		}

		&__text-wrapper:not(:last-child) {
			display: none;
		}

		&__text-wrapper:last-child {
			display: block;
		}

		&__text {
			max-width: 35ch;
		}

		&__playlist {
			--spotify-playlist-embed-aspect-ratio: 5 / 4;

			display: block;
			width: var(--playlist-width);
			margin: 0 auto;
		}
	}
}
