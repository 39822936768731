@use 'mixins';
@use 'variables';

.call-to-action {
	&--show-simple-button {
		padding: 0 var(--spacing-sm);
	}

	&__heading {
		@include mixins.font-t2;
	}

	&__text {
		box-sizing: content-box;
		max-width: 34ch;
		margin-top: var(--spacing-sm);
		padding: 0 var(--spacing-sm);
	}

	&__link {
		margin: var(--spacing-sm) auto 0;
	}

	@media (min-width: variables.$breakpoint-md) {
		display: flex;
		align-items: flex-start;

		&--show-simple-button {
			flex-direction: column;
			align-items: center;
		}

		&__text {
			padding: 0 var(--spacing-lg);
		}

		&__link {
			margin: 0 auto;
		}
	}
}
