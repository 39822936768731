@use 'mixins';

.text-section {
	max-width: 48rem;
	margin: 0 auto;
	padding: 0 var(--spacing-sm);

	white-space: pre-wrap;
	@include mixins.font-t5;
}
