@use 'mixins';
@use 'variables';

.page-header {
	position: relative;
	z-index: 1;
	padding: var(--page-header-padding-block) 0;

	.heading {
		@include mixins.font-t2;
	}

	&:not(.is-alternate) .image-wrapper {
		position: relative;
	}

	.hand-drawn-arrow {
		--width: 4em;
		--aspect-ratio: var(--hand-drawn-arrow-3-aspect-ratio);

		position: absolute;
		top: 40%;
		right: 0;
		width: var(--width);
		height: calc(var(--width) / (var(--aspect-ratio)));

		transform: translateX(0.25em);

		fill: var(--color-core-white);

		font-size: variables.$font-size-t2;
	}

	&.is-alternate .hand-drawn-arrow {
		--width: 8em;
		--aspect-ratio: var(--hand-drawn-arrow-1-aspect-ratio);

		top: auto;
		bottom: 0;

		transform: translate(3em, 40%) rotate(110deg);
	}

	.text {
		@include mixins.font-t4;

		position: relative;
		z-index: 1;

		margin-top: var(--spacing-md);
		padding: 0 var(--spacing-sm);
	}

	&.is-alternate .call-to-action:last-child {
		display: none;
	}

	.call-to-action-link {
		margin: -2em auto 0;
	}

	@media (min-width: variables.$breakpoint-md) {
		display: flow-root;

		.hand-drawn-arrow {
			width: 5em;
		}

		&.is-alternate .hand-drawn-arrow {
			transform: translate(3em, 30%) rotate(70deg);
		}

		.text,
		.call-to-action,
		&.is-alternate .image-wrapper {
			width: 50%;
		}

		.text {
			padding-left: var(--spacing-lg);
		}

		.text,
		&.is-alternate .image-wrapper {
			float: left;
		}

		.call-to-action,
		&.is-alternate .text {
			float: right;
		}

		.call-to-action {
			clear: right;
		}

		&.is-alternate .text {
			padding: 0 var(--spacing-lg) 0 var(--spacing-md);
		}

		&.is-alternate .call-to-action-link {
			margin-top: var(--spacing-md);
		}

		&.is-alternate .call-to-action:not(:last-child) {
			display: none;
		}

		&.is-alternate .call-to-action:last-child {
			display: block;
		}
	}
}
