@use 'mixins';
@use 'variables';

.home-page-teaser-section {
	padding: var(--spacing-md) 0 var(--spacing-lg);

	background-color: var(--background-color, transparent);

	&__heading {
		@include mixins.font-t2;
	}

	&__text {
		margin: var(--spacing-sm) var(--spacing-sm) 0;
	}

	&__form {
		margin: var(--spacing-md) var(--spacing-sm) 0;
	}

	&__image {
		margin-top: var(--spacing-md);
	}

	@media (min-width: variables.$breakpoint-md) {
		display: flex;
		flex-direction: row-reverse;
		align-items: flex-start;
		padding: var(--spacing-xl) 0;

		&--is-alternate {
			flex-direction: row;
		}

		&__content {
			padding: 0 var(--spacing-lg);
		}

		&--is-alternate &__heading {
			text-align: left;
		}

		&__form {
			max-width: 78%;
		}

		&__image {
			flex: 1 0 52vw;
			margin-top: 0;
		}
	}
}
