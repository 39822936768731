@use 'mixins';
@use 'variables';

.membership-upsell-section {
	background-color: var(--color-accent-amethyst);

	&__heading {
		@include mixins.font-t2;
		padding-top: var(--spacing-lg);
	}

	&__content {
		display: flex;
		flex-direction: column;
		padding: 0 var(--spacing-sm) var(--spacing-lg) var(--spacing-sm);
	}

	&__illustration,
	&__text {
		display: flex;
		flex-direction: column;
		justify-content: flex-end;
	}

	&__illustration {
		--illustration-max-width: 73.3vw;

		grid-column: 1 / span 5;
		padding: var(--spacing-md) 3.125rem var(--spacing-md) 3.125rem;
	}

	&__info {
		display: flex;
		grid-column: 8 / span 5;
		flex-direction: column;
		align-items: center;
		justify-content: flex-end;
	}

	&__button-link {
		@include mixins.font-t5;
		width: 100%;
		margin-top: 1.5rem;

		text-transform: initial;

		--button-border-color: transparent;
		--button-background-color: var(--color-core-black);
		--button-text-color: var(--color-core-white);
	}

	@media (min-width: variables.$breakpoint-md) {
		&__heading {
			width: 87%;
		}

		&__illustration {
			--illustration-max-width: 36.7vw;
			padding: 0;
		}

		&__content {
			display: grid;
			grid-gap: var(--spacing-md);
			grid-template-columns: repeat(12, 1fr);
			padding: 0 var(--spacing-lg) var(--spacing-lg) var(--spacing-lg);
		}

		&__info {
			align-items: flex-start;
			margin-top: 0;
		}

		&__button-link {
			width: initial;
			margin-top: 3rem;
		}

		&__info > p {
			padding-top: var(--spacing-md);
		}
	}
}
