@use 'mixins';
@use 'variables';

.job-list {
	&__header {
		position: relative;

		font-size: variables.$font-size-t1;
	}

	&__heading {
		@include mixins.font-t1;

		text-align: right;
	}

	&__hand-drawn-stroke {
		--width: 3.5em;

		position: absolute;
		z-index: -1;
		right: 0;
		bottom: 0;
		width: var(--width);
		height: calc(var(--width) / (var(--hand-drawn-stroke-2-aspect-ratio)));

		transform: translateX(0.3em);

		fill: var(--color-core-white);
	}

	&__position-list {
		margin-top: var(--spacing-sm);
		padding: 0 var(--spacing-sm);
	}

	&__position {
		border-width: 1px 0 0 0;
		border-style: solid;

		&:last-child {
			border-width: 1px 0;
		}
	}

	@media (min-width: variables.$breakpoint-md) {
		&__position-list {
			padding: 0 var(--spacing-lg);
		}
	}
}
