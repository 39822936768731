@use 'mixins';

.header-section {
	padding: 0 var(--spacing-sm);
	h1 {
		@include mixins.font-t1;
	}
	h2 {
		@include mixins.font-t2;
	}
}
