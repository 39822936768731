@use 'variables';
@use 'mixins';

.programme {
	&--is-loading {
		visibility: hidden;
	}

	&__content {
		@include mixins.stack($size: md);

		padding: var(--programme-padding-block, var(--spacing-md))
			var(--programme-padding-inline, var(--spacing-sm));
	}

	&__list--is-thumbnail {
		display: grid;
		gap: var(--spacing-md);
		&.programme__list--show-ticket-anchors {
			gap: var(--spacing-lg) var(--spacing-md);
		}
	}

	@media (min-width: variables.$breakpoint-sm) {
		&__list--is-thumbnail {
			grid-template-columns: repeat(var(--columns, 2), 1fr);
			gap: var(--spacing-md) var(--spacing-sm);
			&.programme__list--show-ticket-anchors {
				gap: var(--spacing-lg) var(--spacing-sm);
			}
		}
	}

	@media (min-width: variables.$breakpoint-md) {
		&__content {
			@include mixins.stack($size: lg);

			padding: var(--programme-padding-block, var(--spacing-lg))
				var(--programme-padding-inline, var(--spacing-lg));
		}

		&__list--is-thumbnail {
			--columns: 3;
		}
	}
}
