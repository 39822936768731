.switcher {
	display: grid;
	grid-template: 1fr / 1fr;
}

.item {
	visibility: hidden;
	grid-area: 1 / 1 / 2 / 2;
}

.item[aria-hidden='false'] {
	visibility: visible;
}

@media (prefers-reduced-motion: no-preference) {
	.item {
		transition: opacity 1500ms ease-out;

		opacity: 0;
	}

	.item[aria-hidden='false'] {
		opacity: 1;
	}
}
