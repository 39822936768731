@use 'variables';

.listing-thumbnail {
	display: flex;
	position: relative;
	flex-direction: column;

	&__anchor > span {
		display: block;

		padding-left: 0.0625em;
	}

	&__header {
		margin-top: var(--spacing-sm);
		margin-bottom: var(--spacing-md);
	}

	&__badge-list {
		margin-top: var(--spacing-xs);
	}

	&__anchors {
		display: block;
		width: 100%;
	}

	@media (min-width: variables.$breakpoint-sm) {
		&__anchors {
			width: fit-content;
		}
	}

	@media (hover: hover) {
		&__anchor > span {
			transition: transform 150ms ease-in-out;
		}

		&__anchor:hover > span {
			transform: translateX(0.25em);
		}
	}
}
