@use 'mixins';
@use 'variables';

.wrapper > * + * {
	margin-top: var(--spacing-md);
}

.quote {
	max-width: 50ch;
	padding: 0 var(--spacing-sm);
}

.quote > * + * {
	margin-top: var(--spacing-sm);
}

.quote blockquote {
	@include mixins.font-t4;
}

.quote p {
	text-indent: -0.425em;
}

.quote p::before {
	content: open-quote;
}

.quote p::after {
	content: close-quote;
}

@media (min-width: variables.$breakpoint-md) {
	.wrapper {
		display: flex;
		align-items: flex-start;
	}

	.wrapper > * + * {
		margin-top: 0;
	}

	.wrapper > img {
		flex: 1 0 50%;
	}

	.quote {
		padding: 0 var(--spacing-lg) 0 4em;
	}
}
