@use 'mixins';
@use 'variables';
@use 'functions';

.block-quote {
	position: relative;

	&__arrow {
		position: absolute;
		width: 91vw;

		transform: translateX(-30%);

		fill: var(--color-core-white);
	}

	figure {
		position: relative;
		z-index: 1;
		padding: 8vw var(--spacing-sm) 0;
	}

	blockquote {
		@include mixins.font-t4;

		max-width: 50ch;
	}

	figcaption {
		@include mixins.font-t5;

		margin-top: var(--spacing-sm);
	}

	@media (min-width: variables.$breakpoint-md) {
		&__arrow {
			width: 45vw;
		}

		blockquote {
			@include mixins.font-t3;
		}

		figure {
			padding: 6vw var(--spacing-lg) 0;
		}
	}
}
