.wrapper {
	position: relative;
}

.label {
	display: flex;
	position: absolute;
	z-index: 1;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	justify-content: space-between;
	padding: var(--spacing-sm);
	gap: var(--spacing-sm);

	span:first-child {
		overflow: hidden;

		text-overflow: ellipsis;
		white-space: nowrap;
	}
}

.arrow {
	flex-shrink: 0;
	width: 0.9em;

	transform: rotate(90deg);
}

.select {
	position: relative;
	z-index: 2;

	text-indent: -100%;
}
