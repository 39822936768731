@use 'mixins';
@use 'variables';

.faq-section {
	@include mixins.stack($size: md);

	overflow: hidden;

	&__header {
		position: relative;

		font-size: variables.$font-size-t1;
	}

	&__heading {
		@include mixins.font-t1;
	}

	&__hand-drawn-arrow {
		--width: 3.6em;
		position: absolute;
		z-index: -1;
		top: 0;
		right: 0;
		width: 3.6em;
		height: calc(var(--width) / (var(--hand-drawn-arrow-1-aspect-ratio)));

		transform: translate(0.8em, -0.65em) rotate(14deg) scaleX(-1);

		fill: var(--color-core-white);
	}

	&__topic {
		@include mixins.stack($size: xs);

		display: block;
		max-width: 64ch;
		padding: 0 var(--spacing-sm);
	}

	&__subheading {
		@include mixins.font-t3-title;
	}

	@media (min-width: variables.$breakpoint-md) {
		&__hand-drawn-arrow {
			transform: translate(0.8em, 0.1em) rotate(14deg) scaleX(-1);
		}

		&__topic {
			padding: 0 var(--spacing-lg);
		}
	}
}
