.button {
	--button-text-color: inherit;

	&.is-dark-text {
		--button-text-color: var(--color-core-black);
	}

	&.is-light-text {
		--button-text-color: var(--color-core-white);
	}

	&.has-bg-color {
		--button-border-color: var(--cta-background-color);
		background-color: var(--cta-background-color);
	}
}

.small-print {
	margin-top: 0.5rem;
}
