.heading {
	font-weight: 700;
}

.button {
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	padding: var(--spacing-sm) 0;
	overflow: hidden;

	font-weight: 700;
	text-align: inherit;
}

.arrow {
	--width: 0.9em;

	display: block;
	width: var(--width);
	height: calc(var(--width) / (var(--arrow-aspect-ratio)));

	transform: rotate(90deg);

	fill: currentColor;
}

button[aria-expanded='true'] .arrow {
	transform: rotate(-90deg);
}

.panel {
	padding-bottom: var(--spacing-sm);
}

.panel[aria-hidden='true'] {
	display: none;
}

@media (prefers-reduced-motion: no-preference) {
	.arrow {
		transition: transform 300ms ease-in-out;
	}
}
