@use 'mixins';
@use 'variables';

.contact-form {
	@include mixins.stack($size: sm);

	.title {
		@include mixins.font-t2;

		max-width: 50vw;
	}

	.text,
	.form {
		padding: 0 var(--spacing-sm);
	}

	.form {
		--button-background-color: var(--color-core-white);
		--button-border-color: transparent;
	}

	.fieldset {
		padding: 0;

		border: 0;

		& > :not([type='hidden']) + * {
			margin-top: var(--spacing-sm);
		}
	}

	@media (min-width: variables.$breakpoint-md) {
		@include mixins.unstack;

		display: flex;
		justify-content: space-between;

		.introduction {
			flex: 0 0 40%;
		}

		.text {
			padding: 0 0 0 var(--spacing-lg);
		}

		.form {
			flex: 0 0 50%;
			padding: 0 var(--spacing-lg) 0 0;
		}
	}
}
