@use 'variables';

// the following properties can be changed by parent components:
// --carousel-gap
// --carousel-scroll-padding (https://developer.mozilla.org/en-US/docs/Web/CSS/scroll-padding)
// --carousel-background-color
// --carousel-justify-content

.carousel {
	--carousel-gap-negate: calc(var(--carousel-gap) * -1);
	--carousel-button-size: min(17vw, 8rem);
	--carousel-pagination-dot-width: 0.75rem;
	--carousel-pagination-dot-color: var(--color-core-black);
	--carousel-pagination-dot-color-active: var(--color-core-white);
	--carousel-pagination-dot-gap: 0.25rem;

	position: relative;
}

.next-button,
.previous-button {
	display: flex;
	position: absolute;
	z-index: 1;
	top: 30%;
	align-items: center;
	justify-content: center;
	width: var(--carousel-button-size);
	height: var(--carousel-button-size);

	background-color: var(
		--carousel-background-color,
		var(--body-background-color-hex)
	);

	&[disabled] {
		display: none;
	}

	& > svg {
		width: 65%;
	}
}

.previous-button {
	left: 0;

	transform: scaleX(-1);
}

.next-button {
	right: 0;
}

.scroller {
	padding-bottom: var(--spacing-md);
	overflow: auto hidden;
	scroll-snap-type: x mandatory;
	scroll-padding: 0 var(--carousel-scroll-padding, 0);

	.carousel--is-scrollbar-hidden & {
		scrollbar-width: none;

		&::-webkit-scrollbar {
			display: none;
		}
	}
}

.list {
	display: flex;
	justify-content: var(--carousel-justify-content, normal);
	gap: var(--carousel-gap);
}

.list::before,
.list::after {
	// hack to add a margin to the left and right which otherwise doesn’t work in safari
	content: '';

	flex: 0 0 var(--carousel-gap);
}

// Move the pseudo element on top of the gap between the pseudo element and the item
.list::before {
	margin-right: var(--carousel-gap-negate);
}

.list::after {
	margin-left: var(--carousel-gap-negate);
}

.item {
	scroll-snap-align: start;
}

// Base pagination styling
.pagination-dots {
	display: flex;
	position: absolute;
	bottom: var(--spacing-sm);
	left: 50%;
	align-items: center;
	justify-content: center;

	transform: translateX(-50%);
}

.pagination-dot {
	width: var(--carousel-pagination-dot-width);
	height: var(--carousel-pagination-dot-width);
	margin: 0 var(--carousel-pagination-dot-gap);

	border: none;
	border-radius: 50%;

	background-color: var(--carousel-pagination-dot-color);

	cursor: pointer;
	&:hover,
	&.active {
		background-color: var(--carousel-pagination-dot-color-active);
	}
}

@supports not (scroll-behavior: smooth) {
	.next-button,
	.previous-button {
		display: none;
	}
}

@media (min-width: variables.$breakpoint-md) {
	.scroller {
		padding-bottom: var(--spacing-lg);
	}
}
