.items {
	display: flex;
	gap: var(--spacing-md);
}

.previous,
.next {
	flex-basis: 50%;
}

.next {
	margin-left: auto;
}
