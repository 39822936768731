.spotify-playlist-embed {
	position: relative;

	&::after {
		content: '';

		display: block;
		padding-bottom: calc(var(--spotify-playlist-embed-aspect-ratio, 1) * 100%);
	}

	&__iframe {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
}
