@use 'mixins';
@use 'variables';

.info-block-section {
	@include mixins.stack($size: md);

	&__secondary-image {
		width: 54%;
	}

	&__blocks {
		@include mixins.stack($size: md);

		padding: 0 var(--spacing-sm);
	}

	&__block {
		@include mixins.stack($size: sm);
	}

	&__heading {
		@include mixins.font-t4($weight: 700);
	}

	&__text {
		max-width: 36ch;
	}

	@media (min-width: variables.$breakpoint-md) {
		@include mixins.unstack;

		display: flex;
		flex-direction: row-reverse;

		&__images {
			width: 52vw;
		}

		&__secondary-image {
			display: block;
			margin-left: auto;
		}

		&__blocks {
			@include mixins.stack($size: lg);

			width: 48vw;
			padding: 0 var(--spacing-lg);
		}
	}
}
