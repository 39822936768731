.button__ticket {
	&--is-live {
		--button-background-color: var(--color-accent-strawberry);
		--button-text-color: var(--color-core-black);
	}
	&--is-club {
		--button-background-color: var(--color-accent-watermelon);
		--button-text-color: var(--color-core-black);
	}
	&--is-live,
	&--is-club {
		--button-border-color: transparent;
	}
	&--is-transparent {
		--button-background-color: transparent;
		--button-text-color: var(--color-core-black);
	}
}
