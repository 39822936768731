.listing-badge-list {
	display: flex;
	flex-wrap: wrap;
	gap: var(--spacing-xs);

	&--is-live {
		--badge-background-color: var(--color-accent-strawberry);
	}

	&--is-club {
		--badge-background-color: var(--color-accent-watermelon);
	}

	&__sold-out {
		--badge-text-color: var(
			--body-background-color-hex,
			var(--color-core-sand)
		);
		--badge-background-color: var(--color-core-black);
		--badge-border-color: transparent;
	}

	&__type {
		--badge-border-color: transparent;
	}

	&__space {
		--badge-background-color: transparent;
	}
}
